<template>
   <div class="col-12 px-0 mb-1" :class="toggleEstoques ? 'main-container' : ''" ref="loja">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-md-start cursor-pointer" @click="toggleEstoques = !toggleEstoques;">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ loja.nomeLoja }}</h1>
                        <p class="font-10 mb-1 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ loja.idLoja }}</span>
                           <span class="ms-3">
                              <i class="far fa-tag font-9 me-1"></i>
                              <span>{{ loja.numeroLoja == null || String(loja.numeroLoja).trim().length == 0 ? 'Sem número' : loja.numeroLoja }}</span>
                           </span>
                        </p>
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-map-marker-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Endereço:</strong>
                           <span> {{ loja.enderecoLoja == null || loja.enderecoLoja.rua == null ? 'Sem endereço' : (loja.enderecoLoja.rua +', '+ loja.enderecoLoja.numero +' - '+ loja.enderecoLoja.bairro) }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <label :class="quantidadeEstoques < parseFloat(loja.estoqueMinimo) ? 'text-red': ''">
                     <i class="far fa-box font-10 me-1" :class="quantidadeEstoques < parseFloat(loja.estoqueMinimo) ? '': 'color-theme'"></i> Quant. Atual
                  </label>
                  <input type="text" class="form-control input-sm wpx-115" :value="quantidadeEstoques" disabled />
               </div>
               <div class="w-max-content text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <label><i class="far fa-box color-theme font-9 me-1"></i> Quant. Mínima</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="loja.estoqueMinimo" />
               </div>
            </div>
         </div>
      </div>

      <div class="sub-container" v-if="toggleEstoques">
         <div class="col-12 mb-3">
            <h1 class="font-15 mt-1 mb-0"><i class="far fa-box font-12 color-theme me-2"></i>Estoques</h1>
         </div>
         
         <estoque v-for="(estoque, index) in loja.estoques" :key="index" :estoque="estoque" :index="index" :estoqueMinimo="loja.estoqueMinimo" :produto="produto" />
         
         <div class="col-12 mt-4 mb-5 text-center" v-if="loja.estoques.length == 0">
            <i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
            <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum estoque encontrado</h4>
         </div>
      </div>
   </div>
</template>

<script>

import estoque from '@/components/produtos/meusProdutos/EstoqueProduto.vue'

export default {
   name: 'EstoqueLoja',
   props: ['loja', 'index', 'produto'],
   data: function () {
      return {
         toggleEstoques: this.index == 0 ? true : false,
         quantidadeEstoques: this.loja.estoques.reduce((total, e) => total + (/^[0-9.-]+$/.test(e.quantidade) ? parseFloat(e.quantidade) : 0), 0)
      }
   },
   components: {
      estoque
   },
   watch: {
      'loja.estoqueMinimo' : function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': this.produto.isDecimal ? 3 : 0}).then((valor) => {
            this.loja.estoqueMinimo = valor
         })
      },
		'loja.estoques': {
			handler: function (value) {
				this.quantidadeEstoques = value.reduce((total, e) => total + (/^[0-9.-]+$/.test(e.quantidade) ? parseFloat(e.quantidade) : 0), 0)
			},
			deep: true
		}
   }
}

</script>